/* Do a parallax animation
 * image 	image to animate
 * section 	section to start the animation
 * vitesse  réduction de la vitesse du parallax
 */
function parallax(image, section, vitesse){
	image = $(image); // image to animation
	section = $(section); // section to animate the image
	
	if(image.length == 0){
		return false;
	}
	
	vitesse = (typeof vitesse === 'undefined') ? 3 : vitesse; // réduction de la vitesse du parallax
	
	
	image.addClass('img-parallax');
	section.addClass('section-parallax');
	
	// ajoute une wrapper pour l'image a l'intérieur de la section pour la garder dans le flow
	$(section).append('<div class="parallax-img-wrapper"></div>').children('.parallax-img-wrapper').append(image);
	
	$(window).on('scroll load', function(event) {
		if(!isMobile()){
			image.removeClass('inactif'); // active le parallax
			
			if(section.isOnScreen() === true){
				var visiblePart = parseInt(section.css('height')); // hauteur de la section = zone visible de l'image
				var maxScroll = image.height() - visiblePart; // hauteur d'extra de l'image
			
				var sectionScroll = $(window).scrollTop() + $(window).height() - section.offset().top;
				
				sectionScroll = sectionScroll/vitesse;
				
				if(sectionScroll >= maxScroll){ // si on a atteint le scroll maximum on reste au max
					image.css('top', maxScroll*-1);
				}else{
					image.css('top', sectionScroll*-1);
				}
			}
		}else{
			image.css('top', '').addClass('inactif'); // mobile reset le top et désactive le parallax
		}
	});
}